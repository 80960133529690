import axios from 'axios';

export default {
    getInvite(inviteCode) {
        return axios.get(window.API_BASE + '/client-invites/' + inviteCode);
    },

    acceptInvite(inviteCode, companyOrPersonal='personal', companyName='') {
        return axios.post(window.API_BASE + '/client-invites/' + inviteCode + '/accept', {
            company_or_personal: companyOrPersonal,
            company_name: companyName
        });
    },

    getInviteToSetupAccount(inviteCode) {
        return axios.get(window.API_BASE + '/client-invites-setup-account/' + inviteCode);
    },

    acceptInviteAndSetupAccount(inviteCode, password, passwordConfirmation, companyOrPersonal='personal', companyName='') {
        return axios.post(window.API_BASE + '/client-invites-setup-account/' + inviteCode + '/accept', {
            password: password,
            password_confirmation: passwordConfirmation,
            company_or_personal: companyOrPersonal,
            company_name: companyName
        });
    }
}