<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">Setup Account</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Redeem -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb-->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>
        <!-- / Breadcrumb -->

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- Redeem Invite -->
        <div v-if="!isLoading && invite" class="row">
          <div class="col-lg-7 position-relative pe-lg-5 mb-5 mb-lg-0" style="z-index: 1025;">

            <h2 class="h3 mb-0">You've been invited to join Syndesi as a client</h2>

            <!-- Account type -->
            <div class="mt-4">
              <label class="form-label fw-bold" for="account-type">Account Type</label>
              <div class="pt-2">
                <div class="form-check form-check-inline">
                  <input v-model="form.company_or_personal"
                         value="personal"
                         class="form-check-input" id="personal" type="radio" name="personal">
                  <label class="form-check-label" for="personal">Personal</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="form.company_or_personal"
                         value="company"
                         class="form-check-input" id="company" type="radio" name="company">
                  <label class="form-check-label" for="company">Company</label>
                </div>
              </div>
            </div>
            <!-- / Account type -->

            <!-- Company name -->
            <div class="mt-4" v-if="form.company_or_personal === 'company'">
              <label class="form-label fw-bold" for="companyName">Company Name</label>
              <input :disabled="isSaving" v-model="form.company_name"
                     class="form-control" type="text" id="companyName">
            </div>
            <!-- / Company name -->

            <!-- Password -->
            <div class="mt-4">
              <label class="form-label fw-bold" for="password">Password</label>
              <input :disabled="isSaving" v-model="form.password"
                     class="form-control" type="password" id="password">
            </div>
            <!-- / Password -->

            <!-- Password Confirmation -->
            <div class="mt-4">
              <label class="form-label fw-bold" for="passwordConfirmation">Password Confirmation</label>
              <input :disabled="isSaving" v-model="form.password_confirmation"
                     class="form-control" type="password" id="passwordConfirmation">
            </div>
            <!-- / Password Confirmation -->

            <div class="mt-4">
              <button class="btn btn-primary"
                      :class="{'opacity-50': (isSaving)}"
                      @click.prevent="acceptInvite"
                      :disabled="(isSaving||!canSave)"
                      type="submit">
                <i v-if="!isSaving" class="fi-check me-2"></i>
                <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                Setup Account
              </button>
            </div>

          </div>
        </div>
        <!-- / Redeem Invite -->
      </div>
    </section>
    <!-- / Redeem -->
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import Forms from "../../mixins/Forms";
import clientInvites from "../../api/client-invites";
import Spinner from "../../components/Spinner";
import LoadingScreen from "../../components/LoadingScreen";
import {useToast} from 'vue-toastification';
import {mapActions} from 'vuex';

const toast = useToast();

export default {
  name: "SetupAccount",
  mixins: [Forms],
  components: {Breadcrumb, Spinner, LoadingScreen},
  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "Setup Account",
          active: true,
        }
      ],
      isLoading: false,
      isSaving: false,
      serverErrors: [],
      invite: null,
      form: {
        company_or_personal: 'personal',
        company_name: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  created() {
    this.form = {
      company_or_personal: 'personal',
      company_name: '',
      password: '',
      password_confirmation: ''
    };
    this.fetch();
  },
  computed: {
    canSave() {
      return (this.invite && this.form.password && this.form.password_confirmation);
    }
  },
  methods: {
    ...mapActions('auth', {
      attemptLogin: 'attemptLogin'
    }),

    fetch() {
      let vm = this;
      vm.invite = null;
      vm.serverErrors = [];
      clientInvites.getInviteToSetupAccount(this.$route.params.id).then(r => {
        vm.isLoading = false;
        vm.invite = r.data;
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.setAndNotifyErrors(e);
      });
    },

    acceptInvite() {
      let vm = this;
      vm.isSaving = true;
      clientInvites.acceptInviteAndSetupAccount(
          this.$route.params.id,
          this.form.password,
          this.form.password_confirmation,
          this.form.company_or_personal,
          this.form.company_name
      ).then(r => {
        vm.isSaving = false;
        toast.success('Account setup successfully');
        vm.attemptLogin({
          email: vm.invite.email,
          password: vm.form.password
        });
        vm.$router.push('/');
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error redeeming invite');
      });
    }
  }
}
</script>

<style scoped>

</style>